<template>
  <button @click="navigate" :aria-label="'Attachment'" :title="'Attachment'" class="action" id="atch-button">
    <i class="material-icons">attach_file</i>
    <span>Attachment</span>
  </button>

</template>

<script>
import { mapState, mapGetters } from "vuex";
const tpl = ".atch[%s].dir";
export default {
  name: "atch-button",
  computed: {
    ...mapState(["req", "selected", "selectedCount"]),
    ...mapGetters(["isListing"]),
  },
  methods: {
    choose: function () {
      if (this.selectedCount === 0 || this.selectedCount > 1) {
        return;
      }
      return this.req.items[this.selected[0]];
    },
    navigate: function () {
      var fi = this.choose();
      if (fi == null) {
        return;
      }
      var fname = tpl.replace("%s", fi.name);
      let re = new RegExp("/" + fi.name + "$");
      var fdir = fi.path.replace(re, "/" + fname + "/");
      this.$router.push({ path: fdir });
    },
    show: function () {
      // this.$store.commit('showHover', 'remark')
    },
  },
};
</script>
