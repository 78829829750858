<template>
  <div class="card floating">
    <div class="card-title">
      <h2>
        Remark<code class="small">[{{ fileName() }}]</code> :
      </h2>
    </div>

    <div class="card-content">
      <p>Input remark content to :</p>
      <input
        class="input input--block"
        v-focus
        type="text"
        @keyup.enter="submit"
        v-model.trim="remark"
      />
      <p>Change the work state to: </p>
      <select class="remarks" v-model="state" :aria-label="state">
        <option value="0">Unstart</option>
        <option value="1">Working</option>
        <option value="2">Finished</option>
        <option value="3">ToFixed</option>
        <option value="4">N/A</option>
      </select>
    </div>
    <div class="card-action">
      <button
        class="button button--flat button--grey"
        @click="$store.commit('closeHovers')"
        :aria-label="$t('buttons.cancel')"
        :title="$t('buttons.cancel')"
      >{{ $t('buttons.cancel') }}</button>
      <button
        @click="submit"
        class="button button--flat"
        type="submit"
        :aria-label="$t('buttons.rename')"
        :title="$t('buttons.rename')"
      >{{ $t('buttons.ok') }}</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import url from "@/utils/url";
import { files as api } from "@/api";

export default {
  name: "remark",
  data: function() {
    return {
      remark: "",
      state: 0
    };
  },
  created() {
    this.remark = this.choose().Remark;
    this.state = this.choose().State;
  },
  computed: {
    ...mapState(["req", "selected", "selectedCount"]),
    ...mapGetters(["isListing"])
  },
  methods: {
    cancel: function() {
      this.$store.commit("closeHovers");
    },
    choose: function() {
      if (this.selectedCount === 0 || this.selectedCount > 1) {
        return;
      }
      return this.req.items[this.selected[0]];
    },
    fileName: function() {
      return this.choose().name;
    },
    submit: async function() {
      let oldLink = "";
      // let newLink = "";
      if (!this.isListing) {
        oldLink = this.req.url;
      } else {
        oldLink = this.req.items[this.selected[0]].url;
      }
      try {
        await api.remarkState({
          from: oldLink,
          to: `${this.state}|${this.remark}`
        });
        this.$store.commit("setReload", true);
      } catch (e) {
        this.$showError(e);
      }
      this.$store.commit("closeHovers");
    },
    submit2: async function() {
      let oldLink = "";
      let newLink = "";

      if (!this.isListing) {
        oldLink = this.req.url;
      } else {
        oldLink = this.req.items[this.selected[0]].url;
      }

      newLink =
        url.removeLastDir(oldLink) + "/" + encodeURIComponent(this.name);

      try {
        await api.move([{ from: oldLink, to: newLink }]);
        if (!this.isListing) {
          this.$router.push({ path: newLink });
          return;
        }

        this.$store.commit("setReload", true);
      } catch (e) {
        this.$showError(e);
      }

      this.$store.commit("closeHovers");
    }
  }
};
</script>
