<template>
  <button @click="show" :aria-label="'remark'" :title="'remark'" class="action" id="remark-button">
    <i>remark</i>
    <span>remark</span>
  </button>
</template>

<script>
export default {
  name: 'remark-button',
  methods: {
    show: function () {
      this.$store.commit('showHover', 'remark')
    }
  }
}
</script>
