<template>
  <div>
    <div v-if="value.length>0" id="message" class="list">
      <div class="item header">
        <div
          class="p1"
        >The following documents have been added or updated in the last 2 days: ({{this.value.length}} files)</div>
        <div class="p2 btn" @click="show()">{{isHidden?"[show]":"[hide ]"}}</div>
      </div>
      <div class="list" v-if="!isHidden">
        <div class="item" v-for="v in value" :key="v.Name">
          <div class="name left">{{v.FileName}}</div>
          <div class="right">{{humanTime(v.Date)}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { news } from "@/api";
import moment from "moment";
export default {
  name: "news",
  data: function() {
    return {
      isHidden: true,
      value: []
    };
  },
  computed: {},
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      news().then(data => {
        this.value = data;
      });
      setTimeout(() => {
        this.fetch();
      }, 1000 * 60);
    },
    show() {
      this.isHidden = !this.isHidden;
    },
    humanTime: function(time) {
      return moment(time).fromNow();
    }
  }
};
</script>