<template>
  <div
    class="item"
    role="button"
    tabindex="0"
    :draggable="isDraggable"
    @dragstart="dragStart"
    @dragover="dragOver"
    @drop="drop"
    @click="click"
    @dblclick="open"
    @touchstart="touchstart"
    :data-dir="isDir"
    :aria-label="name"
    :aria-selected="isSelected"
  >
    <div>
      <img v-if="type==='image'" :src="thumbnailUrl" />
      <i v-else class="material-icons">{{ icon }}</i>
    </div>

    <div>
      <p class="name">
        <span class="p2">{{ GetName() }}</span>
      </p>
      <p class="group">
        <span v-if="isDir">-</span>
        <span v-if="!isDir">{{GetGroup()}}</span>
      </p>
      <p class="atch">
        <span v-if="isDir">-</span>
        <span v-if="!isDir">{{AtchCount}}</span>
      </p>
      <p v-if="isDir" class="ustate size">
        <span class="ustate">
          <span class="u2">{{ GetState() }}</span>
          <img class="u1" :src="GetStateIcon()" alt="hello" />
        </span>
        <span class="uremark" v-if="Remark!=''">
          <img class="u1" :src="GetRemarkIcon()" alt="hello" />
        </span>
      </p>
      <p v-if="!isDir" class="size" :data-order="humanSize()">{{ humanSize() }}</p>
      <p class="modified">
        <time :datetime="modified">{{ humanTime() }}</time>
      </p>
    </div>
  </div>
</template>

<script>
import { baseURL, staticURL } from "@/utils/constants";
import { mapMutations, mapGetters, mapState } from "vuex";
import filesize from "filesize";
import moment from "moment";
import { files as api } from "@/api";

var mapToState = {
  "0": "Unstart",
  "1": "Working",
  "2": "Finished",
  "3": "ToFixed",
  "4": "N/A",
};

export default {
  name: "item",
  data: function () {
    return {
      touches: 0,
      StateData: this.State,
    };
  },
  props: [
    "name",
    "isDir",
    "url",
    "type",
    "size",
    "modified",
    "index",
    "Remark",
    "State",
    "Group",
    "AtchCount",
  ],
  watch: {
    StateData: function () {},
  },
  computed: {
    ...mapState(["selected", "req", "user", "jwt"]),
    ...mapGetters(["selectedCount"]),
    stateClass() {
      return [
        "state" + this.StateData.toString(),
        this.isDir ? "" : "hide",
        "state",
      ];
    },
    stateRemark() {
      return [this.Remark == "" ? "hide" : "", "remark"];
    },
    isSelected() {
      return this.selected.indexOf(this.index) !== -1;
    },
    icon() {
      if (this.isDir) return "folder";
      if (this.type === "image") return "insert_photo";
      if (this.type === "audio") return "volume_up";
      if (this.type === "video") return "movie";
      return "insert_drive_file";
    },
    isDraggable() {
      return this.user.perm.rename;
    },
    canDrop() {
      if (!this.isDir) return false;

      for (let i of this.selected) {
        if (this.req.items[i].url === this.url) {
          return false;
        }
      }

      return true;
    },
    thumbnailUrl() {
      const path = this.url.replace(/^\/files\//, "");
      return `${baseURL}/api/preview/thumb/${path}?auth=${this.jwt}&inline=true`;
    },
  },
  methods: {
    ...mapMutations(["addSelected", "removeSelected", "resetSelected"]),
    Edit() {},
    GetName() {
      return this.name;
    },
    GetState() {
      return "[" + mapToState[this.StateData] + "]";
    },
    GetGroup() {
      return this.Group != "" ? this.Group : "N/A";
    },
    GetStateIcon() {
      return `${staticURL}/img/state/${this.StateData}.png`;
    },
    GetRemarkIcon() {
      return `${staticURL}/img/state/remark.png`;
    },
    humanSize: function () {
      return filesize(this.size);
    },
    humanTime: function () {
      return moment(this.modified).fromNow();
    },
    dragStart: function () {
      if (this.selectedCount === 0) {
        this.addSelected(this.index);
        return;
      }

      if (!this.isSelected) {
        this.resetSelected();
        this.addSelected(this.index);
      }
    },
    dragOver: function (event) {
      if (!this.canDrop) return;
      event.preventDefault();
      let el = event.target;
      for (let i = 0; i < 5; i++) {
        if (!el.classList.contains("item")) {
          el = el.parentElement;
        }
      }
      el.style.opacity = 1;
    },
    drop: function (event) {
      if (!this.canDrop) return;
      event.preventDefault();

      if (this.selectedCount === 0) return;

      let items = [];

      for (let i of this.selected) {
        items.push({
          from: this.req.items[i].url,
          to: this.url + this.req.items[i].name,
        });
      }

      api
        .move(items)
        .then(() => {
          this.$store.commit("setReload", true);
        })
        .catch(this.$showError);
    },
    click: function () {
      // return;
      if (this.selectedCount !== 0) event.preventDefault();
      if (this.$store.state.selected.indexOf(this.index) !== -1) {
        this.removeSelected(this.index);
        return;
      }

      if (event.shiftKey && this.selected.length > 0) {
        let fi = 0;
        let la = 0;

        if (this.index > this.selected[0]) {
          fi = this.selected[0] + 1;
          la = this.index;
        } else {
          fi = this.index;
          la = this.selected[0] - 1;
        }

        for (; fi <= la; fi++) {
          if (this.$store.state.selected.indexOf(fi) == -1) {
            this.addSelected(fi);
          }
        }

        return;
      }

      if (!event.ctrlKey && !this.$store.state.multiple) this.resetSelected();
      this.addSelected(this.index);
    },
    touchstart() {
      setTimeout(() => {
        this.touches = 0;
      }, 300);

      this.touches++;
      if (this.touches > 1) {
        this.open();
      }
    },
    open: function () {
      this.$router.push({ path: this.url });
    },
  },
};
</script>
