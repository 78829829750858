<template>
  <header>
    <div>
      <button
        @click="openSidebar"
        :aria-label="$t('buttons.toggleSidebar')"
        :title="$t('buttons.toggleSidebar')"
        class="action"
      >
        <i class="material-icons">menu</i>
      </button>
      <img :src="logoURL" alt="File Browser" />
      <search v-if="isLogged"></search>
    </div>
    <div>
      <template v-if="isLogged">
        <button
          @click="openSearch"
          :aria-label="$t('buttons.search')"
          :title="$t('buttons.search')"
          class="search-button action"
        >
          <i class="material-icons">search</i>
        </button>

        <button
          v-show="showSaveButton"
          :aria-label="$t('buttons.save')"
          :title="$t('buttons.save')"
          class="action"
          id="save-button"
        >
          <i class="material-icons">save</i>
        </button>

        <button
          @click="openMore"
          id="more"
          :aria-label="$t('buttons.more')"
          :title="$t('buttons.more')"
          class="action"
        >
          <i class="material-icons">more_vert</i>
        </button>

        <!-- Menu that shows on listing AND mobile when there are files selected -->
        <div id="file-selection" v-if="isMobile && isListing">
          <span v-if="selectedCount > 0">{{ selectedCount }} selected</span>
          <share-button v-show="showShareButton"></share-button>
          <rename-button v-show="showRenameButton"></rename-button>
          <copy-button v-show="showCopyButton"></copy-button>
          <move-button v-show="showMoveButton"></move-button>
          <delete-button v-show="showDeleteButton"></delete-button>
        </div>

        <!-- This buttons are shown on a dropdown on mobile phones -->
        <div id="dropdown" :class="{ active: showMore }">
          <div v-if="!isListing || !isMobile">
            <remark-button v-show="showRemarkButton"></remark-button>
            <atch-button v-show="showAtchButton"></atch-button>
            <share-button v-show="showShareButton"></share-button>
            <rename-button v-show="showRenameButton"></rename-button>
            <copy-button v-show="showCopyButton"></copy-button>
            <move-button v-show="showMoveButton"></move-button>
            <delete-button v-show="showDeleteButton"></delete-button>
          </div>

          <shell-button v-show="false" />
          <switch-button v-show="isListing"></switch-button>
          <download-button v-show="showDownloadButton"></download-button>
          <upload-button v-show="showUpload"></upload-button>
          <info-button v-show="isFiles"></info-button>

          <button
            v-show="isListing"
            @click="toggleMultipleSelection"
            :aria-label="$t('buttons.selectMultiple')"
            :title="$t('buttons.selectMultiple')"
            class="action"
          >
            <i class="material-icons">check_circle</i>
            <span>{{ $t("buttons.select") }}</span>
          </button>
        </div>
      </template>

      <div v-show="showOverlay" @click="resetPrompts" class="overlay"></div>
    </div>
  </header>
</template>

<script>
import Search from "./Search";
import InfoButton from "./buttons/Info";
import DeleteButton from "./buttons/Delete";
import RenameButton from "./buttons/Rename";
import UploadButton from "./buttons/Upload";
import DownloadButton from "./buttons/Download";
import SwitchButton from "./buttons/SwitchView";
import MoveButton from "./buttons/Move";
import CopyButton from "./buttons/Copy";
import ShareButton from "./buttons/Share";
import ShellButton from "./buttons/Shell";
import RemarkButton from "./buttons/Remark";
import AtchButton from "./buttons/Atch";
import { mapGetters, mapState } from "vuex";
import { logoURL } from "@/utils/constants";
import * as api from "@/api";
import buttons from "@/utils/buttons";

export default {
  name: "header-layout",
  components: {
    Search,
    InfoButton,
    DeleteButton,
    ShareButton,
    RenameButton,
    DownloadButton,
    CopyButton,
    UploadButton,
    SwitchButton,
    MoveButton,
    ShellButton,
    RemarkButton,
    AtchButton,
  },
  data: function() {
    return {
      width: window.innerWidth,
      pluginData: {
        api,
        buttons,
        store: this.$store,
        router: this.$router,
      },
    };
  },
  created() {
    window.addEventListener("resize", () => {
      this.width = window.innerWidth;
    });
  },
  computed: {
    ...mapGetters([
      "selectedCount",
      "isFiles",
      "isEditor",
      "isListing",
      "isLogged",
    ]),
    ...mapState(["req", "selected", "user", "loading", "reload", "multiple"]),
    logoURL: () => logoURL,
    isMobile() {
      return this.width <= 736;
    },
    showRemarkButton() {
      return false; //CLOSE PERMISIION
      if (this.selectedCount != 1) {
        return false;
      }
      var item = this.req.items[this.selected[0]];
      if (!item.isDir) {
        return false;
      }
      return this.isListing && this.user.perm.create;
    },
    showAtchButton() {
      return false; //CLOSE PERMISIION
      if (this.selectedCount != 1) {
        return false;
      }
      var item = this.req.items[this.selected[0]];
      if (item.isDir) {
        return false;
      }

      if (this.$route.path.match(/\.atch\[.+?\]\.dir/)) {
        return false;
      }
      return true;
    },
    showUpload() {
      return false; //CLOSE PERMISIION
      return this.isListing && this.user.perm.create;
    },
    showSaveButton() {
      return false; //CLOSE PERMISIION
      return this.isEditor && this.user.perm.modify;
    },
    showDownloadButton() {
      if (this.selectedCount != 1) {
        return false;
      }
      var item = this.req.items[this.selected[0]];
      if (item.isDir) {
        return false;
      }
      return this.isFiles && this.user.perm.download;
    },
    showDeleteButton() {
      return false; //CLOSE PERMISIION
      if (!this.user.perm.admin) {
        for (const ik of this.selected) {
          if (this.req.items[ik].isDir) {
            return false;
          }
        }
      }
      return (
        this.isFiles &&
        (this.isListing
          ? this.selectedCount !== 0 && this.user.perm.delete
          : this.user.perm.delete)
      );
    },
    showRenameButton() {
      return false; //CLOSE PERMISIION
      return (
        this.isFiles &&
        (this.isListing
          ? this.selectedCount === 1 && this.user.perm.rename
          : this.user.perm.rename)
      );
    },
    showShareButton() {
      return false;
    },
    showMoveButton() {
      return false;
      // return (
      //   this.isFiles &&
      //   (this.isListing
      //     ? this.selectedCount > 0 && this.user.perm.rename
      //     : this.user.perm.rename)
      // );
    },
    showCopyButton() {
      return false;
    },
    showMore() {
      return this.isFiles && this.$store.state.show === "more";
    },
    showOverlay() {
      return this.showMore;
    },
  },
  methods: {
    openSidebar() {
      this.$store.commit("showHover", "sidebar");
    },
    openMore() {
      this.$store.commit("showHover", "more");
    },
    openSearch() {
      this.$store.commit("showHover", "search");
    },
    toggleMultipleSelection() {
      this.$store.commit("multiple", !this.multiple);
      this.resetPrompts();
    },
    resetPrompts() {
      this.$store.commit("closeHovers");
    },
  },
};
</script>
